import React, { Component } from "react"

class Logout extends Component {
    state = {
        navigate: false
    };

    logout = () => {
        sessionStorage.clear('token');
        sessionStorage.clear('lastLogin');
        this.setState({navigate: true});
    };

    render () {
        const { navigate } = this.state;

        if (navigate) {
            // console.log(navigate);
            // return window.location.href = '/';
        }

        return <a href="/frontend" className="block px-4 py-2 text-sm text-gray-700" onClick={this.logout}>Sign out</a>
    }
}

export default Logout;
