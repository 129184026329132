import React, { Component } from "react";

export default class UserWelcomeModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastLoginSuccess: this.props.lastLoginSuccess,
      lastLoginFailed: this.props.lastLoginFailed
    };
  }

  render() {
    const { toggleWelcome } = this.props;

    return (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-6xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    Welcome
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => toggleWelcome(false)}
                  >
                    <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  {this.state.lastLoginSuccess ? 
                    <><small><i>Your last successful login:</i></small><br />{this.state.lastLoginSuccess}</> : 
                    <>This is your first successful login</>
                  }
                  {this.state.lastLoginFailed ? 
                    <><br /><br />Last failed login:<br />{this.state.lastLoginFailed}</> : 
                    null
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
  );
}}
