import React, { Component } from "react";
import ReactSlider from 'react-slider';
import '../slider.css'

export default class Slider extends Component {
    constructor(props) {
      super(props);
      this.state = {
        classification: this.props.classification,
        index: this.props.index,
        investigationId: this.props.investigationId
      };
    }
  
    setRangeClass = (values) => {
        const item = this.state.classification;
        item.start_value = values[0];
        item.end_value = values[1];
        this.setState({ classification: item });
    };

    render() {
        const { onSave } = this.props;
        return (
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                defaultValue={[this.state.classification.start_value, this.state.classification.end_value]}
                ariaLabel={['Start', 'End']}
                ariaValuetext={state => `Slide value ${this.state.classification.start_value}`}
                renderThumb={(props, state) => <div {...props}>{state.valueNow /100 < 1 ? (state.valueNow === 0 ? 0  : '.' + state.valueNow) : 1}</div>}
                pearling
                minDistance={1}
                onChange={ this.setRangeClass }
                onAfterChange={() => onSave(this.state.index, this.state.classification)} 
            />
        )
    }
}
