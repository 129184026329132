import React, { Component } from 'react';
import axios from "axios";
import * as constClass from './Constants';
import Investigation from './Investigation';
import InvestigationModal from './InvestigationModal';
import UserWelcomeModal from './UserWelcomeModal';
import Truncate from './Truncate';
 
class Investigations extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true,
      idToOpen: props.id,
      investigationsList: [],
      formItem: {},
      openItem: {},
      showModal: false,
      showUserWelcome: false,
      lastLoginSuccess: null,
      lastLoginFailed: null,
      bottomOffsetTop:0
    };
  }

  componentDidMount() {
    if (this.state.idToOpen) {
      const item = {id: this.state.idToOpen};
      this.setState({ openItem: item });
    }
  
    this.checkFirstLogin();

    this.refreshList();

    this.setViewHeight();
    // window.addEventListener('resize', this.updateViewHeight);
  }

  checkFirstLogin = () => {
    const noWelcome = sessionStorage.getItem('no_welcome');
    if (!noWelcome) {
      const tokenString = sessionStorage.getItem('token');
      const userToken = tokenString ? JSON.parse(tokenString).token : null;

      axios
        .post(`${constClass.BACKEND_URI}/backend/api/usersettings/`, {'token': userToken}, getHeaders())
        .then((res) => {
            const data = res.data;
            let lastLoginSuccess = data.last_login ? this.formatDateTime(data.last_login) : false;
            let lastLoginFailed = data.failed_login ? this.formatDateTime(data.failed_login) : false;
            this.setState({ lastLoginSuccess });
            this.setState({ lastLoginFailed });
            this.setState({ showUserWelcome: true });
            sessionStorage.setItem('no_welcome', true);
        })
        .catch((err) => console.log(err));
    }
  }

  formatDateTime = (date) => {
    date = new Date(date);
    const year = date.getFullYear();
    const month = date.getMonth() +1;
    const day = date.getDate();
    const hour = date.getHours();
    const minute = date.getMinutes();
    return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year  + ' at ' + hour + ':' + minute;
  };


  setViewHeight = () => {
    var bottomOffsetTop = this.footElement.getBoundingClientRect().top;
    this.setState({ bottomOffsetTop });
  };
  
  refreshList = () => {
    axios
      .get(`${constClass.BACKEND_URI}/backend/api/investigations/`, getHeaders())
      .then((res) => {
        this.setState({ investigationsList: res.data });
        this.setState({ isLoading: false });
      })
      .catch((err) => console.log(err));
  };

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleWelcome = () => {
    this.setState({ showUserWelcome: !this.state.showUserWelcome });
  };

  handleSubmit = (item) => {
    this.toggle();
    item.user = 1;
    item.active = true;

    if (item.id) {
      axios
        .put(`${constClass.BACKEND_URI}/backend/api/investigations/${item.id}/`, item, getHeaders())
        .then((res) => this.refreshList());
      return;
    }

    axios
      .post(`${constClass.BACKEND_URI}/backend/api/investigations/`, item, getHeaders())
      .then((res) => {
        this.refreshList();
        this.saveVisualization(res.data);
      })
      .catch((err) => console.log(err));
  };

  saveVisualization = (investigation) => {
    const visualization = {
        title: "Dashoard " + investigation.title,
        description: "Vizualisation " + investigation.description,
        target: "/kibana/app/dashboards#/view/62ebfdb0-c081-11ec-aeeb-c7316c01614a",
        active: true,
        investigation: investigation.id
    }
    axios
        .post(`${constClass.BACKEND_URI}/backend/api/visualizations/`, visualization, getHeaders());
  };

  handleDelete = (item) => {
    axios
      .delete(`${constClass.BACKEND_URI}/backend/api/investigations/${item.id}/`, getHeaders())
      .then((res) => this.refreshList());
  };

  createItem = () => {
    const item = { name: "", description: "" };
    this.setState({ formItem: item, showModal: !this.state.showModal });
  };

  editItem = (item) => {
    this.setState({ formItem: {id: item.id, name: item.name, description: item.description}, showModal: !this.state.showModal });
  };

  openItem = (item) => {
    this.setState({ openItem: item });
    SetHistory('/frontend/investigation/' + item.id);
  };
  
  closeItem = (item) => {
    this.setState({ openItem: {} });
    SetHistory('/frontend/');
  };
  
  renderItems = () => {
    const newItems = this.state.investigationsList.filter(
      (item) => true//item.active === viewActive
    );

    return (
      <>
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Investigation name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date created
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {newItems.map((item, index) => {
                  return (
                    <tr 
                      key={'i-' + item.id}
                      className={index %2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td 
                        className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                      >
                        <Truncate 
                          string={item.name}
                          length="30"
                        />
                      </td>
                      <td 
                        className="truncate max-w-xs px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                        title={item.description}
                      >
                        <Truncate 
                          string={item.description}
                          length="30"
                        />
                      </td>
                      <td 
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {item.created}
                      </td>
                      <td 
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                      >
                        <button
                            className="mr-2 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-800 bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
                            onClick={() => this.openItem(item)}
                            title="View"
                          >
                            <i className="fas fa-eye"></i>
                          </button>
                          <button
                            className="mr-2 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-50 bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            onClick={() => this.editItem(item)}
                            title="Edit"
                          >
                            <i className="fas fa-edit"></i>
                          </button>
                          <button
                            className="items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-50 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => {
                              const confirmBox = window.confirm(
                                "Do you really want to delete this item?"
                              )
                              if (confirmBox === true) {
                                this.handleDelete(item)
                              }
                            }}
                            title="Delete"
                          >
                            <i className="fas fa-trash"></i>
                          </button>
                      </td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
        <button
          className="float-right my-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={this.createItem}
        >
          Add investigation
        </button>
        {this.state.showModal ? (
          <InvestigationModal 
            formItem={this.state.formItem}
            toggle={this.toggle}
            onSave={this.handleSubmit}
          />
        ) : null}
        {this.state.showUserWelcome ? (
          <UserWelcomeModal 
            lastLoginSuccess={this.state.lastLoginSuccess}
            lastLoginFailed={this.state.lastLoginFailed}
            toggleWelcome={this.toggleWelcome}
          />
        ) : null}
      </>
    )
  };

  render() {
    return (
      <>
        <header className="bg-white shadow">
          <div className="py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">
              {this.state.openItem.id ? <>
                <button 
                  type="button" 
                  onClick={() => this.closeItem()}
                  className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <i className="fas fa-chevron-left"></i>
                </button>&nbsp;</> : null
              }
              Investigations
            </h1>
          </div>
        </header>
        <main>
          <div className="card p-3 pb-0">
            <div className="flex flex-col">
              <div className="">
                <div className="pb-0 align-middle inline-block min-w-full sm:px-2 lg:px-4">
                  {this.state.isLoading ? '...' : 
                    this.state.openItem.id ? 
                      <Investigation 
                        id={this.state.openItem.id}
                        bottomOffsetTop={this.state.bottomOffsetTop}
                      /> :
                      this.renderItems()
                  }
                </div>
              </div>
            </div>
          </div>
        </main>
        <footer 
          className="fixed bottom-0 data-iframe-height"
          ref={ (footElement) => { this.footElement = footElement } }
        ></footer>
      </>
    );
  }
}
 
export default Investigations;

function getHeaders() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = tokenString ? JSON.parse(tokenString).token : null;

  return {
      headers: {
        'Authorization': 'token ' + userToken
      }
  }
}

function SetHistory(url) {
  window.history.pushState('', 'New Page Title', url);
  // history.pushState({}, "", url);
}
