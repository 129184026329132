import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import Navbar from "./components/Navbar";
import Investigations from "./components/Investigations";
import InvestigationSingle from "./components/InvestigationSingle"
import Assets from "./components/Assets"
import Dashboard from "./components/Dashboard";
import Logs from "./components/Logs";
import Downloads from "./components/Downloads";
// import Profile from "./components/Profile"
import Login from "./components/Login";
import Logout from "./components/Logout"
// import ForgotPassword from "./components/ForgotPassword"
import useToken from './components/useToken';
import Footer from './components/Footer';

function App() {
    const { token, setToken } = useToken();

    if(!token) {
      return (
        <div className="App tilt">
          <Login setToken={setToken} />
          <Footer />
        </div>
      )
    }

    return (
      <Router basename='/frontend/'>
           <div className="App tilt">
              <Navbar />
              <Routes>
                <Route exact path='/' element={<Investigations/>}></Route>
                <Route exact path='/investigation/:id' element={<InvestigationSingle/>}></Route>
                <Route exact path='/assets' element={<Assets/>}></Route>
                <Route exact path='/dashboard/:platform' element={<Dashboard/>}></Route>
                <Route exact path='/logs' element={<Logs/>}></Route>
                <Route exact path='/downloads' element={<Downloads/>}></Route>
                <Route exact psath='/dashboard' element={<Dashboard/>}></Route>
                {/* <Route exact path='/profile' element={<Profile/>}></Route> */}
                <Route exact path='/logs' element={<Logs/>}></Route>
                <Route exact path='/login' element={<Login setToken={setToken}/>}></Route>
                <Route exact path='/logout' element={<Logout/>}></Route>
                {/* <Route exact path='/forgot-password' element={<ForgotPassword/>}></Route> */}
              </Routes>
              <Footer />
           </div>
       </Router>
    );
}

export default App;
