export const Menuitems = [
    {
        label: 'Investigations',
        title: 'Investigations',
        url: '/',
        cName: 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75',
        current: true
    },
    {
        label: 'Assets',
        title: 'Assets',
        url: '/assets',
        cName: 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75',
        current: false
    },
    {
        label: 'Dashboard',
        title: 'Dashboard',
        url: '/dashboard',
        cName: 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75',
        current: false
    },
    {
        label: 'Logs',
        title: 'Logs',
        url: '/logs',
        cName: 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75',
        current: false
    },
    {
        label: 'Downloads',
        title: 'Downloads',
        url: '/downloads',
        cName: 'px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:opacity-75',
        current: false
    }
]