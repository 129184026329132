import React, { Component } from "react";
import CharacterCounter from 'react-character-counter'

export default class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formItem: this.props.formItem
    };
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    const formItem = { ...this.state.formItem, [name]: value };

    this.setState({ formItem });
  };

  render() {
    const { toggle, onSave } = this.props;

    return (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-1/2 min-w-max my-6 mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {this.state.formItem.id ? 'Edit' : 'Create a new'} filter
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => toggle(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <form 
                        className="space-y-6"
                        onSubmit={() => onSave(this.state.formItem)} 
                    >
                      <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                              Filter name
                          </label>
                          <div className="mt-1">
                            {this.state.formItem.id ? 
                              <span>{this.state.formItem.name}</span> : 
                              <>
                                <CharacterCounter
                                  value={this.state.formItem.name}
                                  maxLength={50}
                                >
                                  <input 
                                      id="name" 
                                      name="name" 
                                      type="text" 
                                      value={this.state.formItem.name}
                                      onChange={this.handleChange}
                                      maxLength={50}
                                      placeholder="Enter Filter name"
                                      required 
                                      className="appearance-none max-w-lg block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                                  />
                                </CharacterCounter>
                              </>
                            }
                          </div>
                      </div>

                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          type="button"
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={() => toggle(false)}
                        >
                          Close
                        </button>
                        <button 
                          type="submit"
                          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save
                        </button>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
  );
}}
