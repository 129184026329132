import React from 'react';
import { useParams, NavLink } from 'react-router-dom';
import { KIBANA_BASE } from './Constants';

export default function Dashboard() {
  const { platform } = useParams()
  const urlBase = '/kibana/app/dashboards#/view/'
  const params = "embed=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:'2021-11-15T19:17:26.412Z',to:now))&show-query-input=true&show-time-filter=true"
  const dashboards = [
    {platform:'twitter', title:'Dashboard thopter Twitter', id: '5de949c0-c096-11ec-aeeb-c7316c01614a'},
    {platform:'reddit', title:'Dashboard thopter Reddit', id: 'ffa7de70-c0c3-11ec-aeeb-c7316c01614a'},
    {platform:'youtube', title:'Dashboard thopter Youtube', id: 'ef085c90-c0bc-11ec-aeeb-c7316c01614a'},
  ];
  const dashboard = dashboards.filter(
    (item) => item.platform === platform
  )[0];

  let url = KIBANA_BASE + urlBase + '62ebfdb0-c081-11ec-aeeb-c7316c01614a?' + params;
  let title = 'Quick data overview ';

  if (dashboard) {
    url = KIBANA_BASE + urlBase + dashboard.id + '?' + params;
    title = dashboard.title;
  }

  return (
    <>
      <header className="bg-white shadow">
        <div className="py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="inline-block float-left mr-6 text-3xl font-bold text-gray-900">
                Dashboard
            </h1>
            <div className="flex space-x-4">
              {dashboards.map((item, index) => {
                const isActive = platform === item.platform;
                return (
                  <NavLink
                    key={index}
                    to={'/dashboard/' + item.platform}
                    className={
                      (isActive ? 'bg-gray-900 text-white' : 'text-gray-600 hover:bg-gray-700 hover:text-white') +
                      ' nav-link block font-medium text-xs leading-tight uppercase rounded px-6 py-3 md:mr-2 focus:outline-none focus:ring-0'
                    }
                  >
                    {item.platform}
                  </NavLink>
                )
              })}
          </div>
        </div>
      </header>
      <main>
        <div className="max-w-full mx-auto py-6 sm:px-6 lg:px-8">
          <div className="grid grid-cols-4 gap-4">
            <div className="col-span-4 h-full">
              <iframe 
                title={title} 
                src={url}
                height="645" 
                width="100%">
              </iframe>
            </div>
          </div>
        </div>
      </main>
    </>
  )
}
