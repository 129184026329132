import React, { Component } from 'react';
import Select from 'react-select'
import axios from "axios";
import * as constClass from './Constants';
import AssetModal from './AssetModal';
import Truncate from './Truncate';
 
class Assets extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true,
      assetsList: [],
      investigationsList: [],
      formItem: {},
      showModal: false,
    };
  }

  componentDidMount() {
    this.getInvestigations();
  }

  getAssets = () => {
    axios
      .get(`${constClass.BACKEND_URI}/backend/api/assets/`, getHeaders())
      .then((res) => {
        var assets = res.data;
        const investigationsList = this.state.investigationsList;
        for (const index in assets) {
          var connectedInvests = [];
          if (assets[index].investigation.length) {
            for (const investigationId of assets[index].investigation) {
              const investigation = investigationsList.filter((i) => i.value === investigationId);
              if (investigation.length) {
                connectedInvests.push(investigation[0]);
              }
            }
          }
          assets[index].connectedInvests = connectedInvests;
        }
        this.setState({ assetsList: assets });
        this.setState({ isLoading: false });
      })
      .catch((err) => console.log(err));
  };

  getInvestigations = () => {
    axios
      .get(`${constClass.BACKEND_URI}/backend/api/investigations/`, getHeaders())
      .then((res) => {
        var investigations = res.data;
        const investigationsList = [];
        for (const investigation of investigations) {
          investigationsList.push({ value: investigation.id, label: investigation.name });
        }
        this.setState({ investigationsList })
        this.getAssets()
      })
      .catch((err) => console.log(err));
  };

  handleInvestigationChange = (selectedOption, assetId) => {
    var assetsList = this.state.assetsList;
    var assetIndex = assetsList.map(function(x) {return x.id; }).indexOf(assetId);
    var asset = assetsList[assetIndex];

    var investigationIds = [];
    for (const investigation of selectedOption) {
      investigationIds.push(investigation.value);
    }
    asset.investigation = investigationIds;

    assetsList[assetIndex].investigation = investigationIds;
    this.setState({ assetsList });

    let save = {
      id: asset.id, 
      name: asset.name, 
      description: asset.description, 
      active: asset.active,
      investigation: asset.investigation
    }
  
    axios
      .put(`${constClass.BACKEND_URI}/backend/api/assets/${save.id}/`, save, getHeaders());
  };

  edit = (item) => {
    console.log(item)
    this.setState({ formItem: {id: item.id, name: item.name, description: item.description, investigation: item.investigation}, showModal: !this.state.showModal });
  };

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleUpdate = (asset) => {
    this.toggleModal();

    axios
        .put(`${constClass.BACKEND_URI}/backend/api/assets/${asset.id}/`, asset, getHeaders())
        .then((res) => this.getAssets());
  };
  
  handleDelete = (asset) => {
    axios
        .delete(`${constClass.BACKEND_URI}/backend/api/assets/${asset.id}/`, getHeaders())
        .then((res) => {
            this.getAssets()
        });
  };

  renderItems = () => {
    const assets = this.state.assetsList.filter(
      (item) => true//item.active === viewActive
    );

    return (
      <>
        <div className="shadow pb-3 border-b border-gray-200 sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Asset name
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Description
                </th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Investigations
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {assets.map((item, index) => {
                  return (
                    <tr 
                      key={item.id}
                      className={index %2 === 0 ? 'bg-white' : 'bg-gray-50'}
                    >
                      <td 
                        className="px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        <Truncate 
                          string={item.name}
                          length="50"
                        />
                      </td>
                      <td 
                        className="truncate max-w-xs px-6 py-4 text-sm font-medium text-gray-900"
                        title={item.description}
                      >
                        <Truncate 
                          string={item.description}
                          length="50"
                        />
                      </td>
                      <td 
                        className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        <Select 
                          options={this.state.investigationsList} 
                          isMulti="true"
                          menuPlacement="auto"
                          defaultValue={item.connectedInvests}
                          isClearable={false}
                          onChange={e=>this.handleInvestigationChange(e, item.id)}
                        />
                      </td>
                      <td className="whitespace-nowrap">
                        <button
                            className="mr-2 items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-50 bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                            onClick={() => this.edit(item)}
                            title="Edit"
                        >
                            <i className="fas fa-edit"></i>
                        </button>
                        {this.state.showModal ? (
                            <AssetModal 
                                formItem={this.state.formItem}
                                toggle={this.toggleModal}
                                onSave={this.handleUpdate}
                            />
                            ) : 
                            null
                        }
                        <button
                            className="items-center px-4 py-2 mr-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-gray-50 bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => {
                                const confirmBox = window.confirm(
                                    "Do you really want to delete this item?"
                                )
                                if (confirmBox === true) {
                                    this.handleDelete(item)
                                }
                            }}
                            title="DELETE"
                        >
                            <i className="fas fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  )
              })}
            </tbody>
          </table>
        </div>
      </>
    )
  };

  render() {
    return (
      <>
        <header className="bg-white shadow">
          <div className="py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">
              Assets
            </h1>
          </div>
        </header>
        <main>
          <div className="card p-3">
            <div className="flex flex-col">
              <div className="">
                <div className="py-2 pb-11 align-middle inline-block min-w-full sm:px-2 lg:px-4">
                  {this.state.isLoading ? '...' : this.renderItems()}
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}
 
export default Assets;

function getHeaders() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = tokenString ? JSON.parse(tokenString).token : null;

  return {
      headers: {
        'Authorization': 'token ' + userToken
      }
  }
}
