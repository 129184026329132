import { Component } from 'react';

export default class Truncate extends Component {
    render() {
        const { string, length } = this.props;
        return (
            <>
                <span 
                    title={string}
                >
                    {string.length > length ? string.substring(0, (length -3)) + "..." : string}
                </span>
            </>
        )
    }
}
