import React from 'react';
import { useParams } from 'react-router-dom';
import Investigations from "./Investigations";

export default function InvestigationSingle() {
  const { id } = useParams()

  return (
    <Investigations id={id}/>
  )
}