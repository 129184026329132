import { useState } from 'react';

export default function useToken() {
    const getToken = () => {
      const tokenString = sessionStorage.getItem('token');
      const userToken = JSON.parse(tokenString);
      return userToken?.token
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
	    document.cookie = 'token=' + userToken.token + '; path=/; SameSite=true'
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken.token);
    };

    return {
        setToken: saveToken,
        token
    }
}
