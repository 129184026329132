import React, { Component } from 'react';
import axios from "axios";
import ReactPaginate from 'react-paginate';
import * as constClass from './Constants';
import Truncate from './Truncate';
 
class Logs extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            logsList: [],
            nrResults: 0
        };
        this.itemsPerPage = 10;
    }

    componentDidMount() {
        this.getList();
    }

    getList = (page) => {
        const pageNr = page | 0;
        console.log(pageNr);
        axios
          .get(`${constClass.BACKEND_URI}/backend/api/loglinepage/${pageNr}/`, getHeaders())
          .then((res) => {
            console.log(res.data);
            this.setState({ logsList: res.data.results });
            this.setState({ nrResults: res.data.nr_of_logs });
            this.setState({ isLoading: false });
          })
          .catch((err) => console.log(err));
    };

    formatDate = (date, format) => {
        if (date === null) {
            return null;
        }
        if (date instanceof Date && !isNaN(date.valueOf())) {
        } else {
            date = new Date(date);
        }
        const year = date.getFullYear();
        const month = date.getMonth() +1;
        const day = date.getDate();
        const hour = date.getHours();
        const minute = date.getMinutes();
        // const time = date.get
        if (format === 'dd/MM/Y H:i') {
            return (day < 10 ? '0' + day : day) + '/' + (month < 10 ? '0' + month : month) + '/' + year + ' ' + hour + ':' + minute;
        }
        return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day) + ' ' + hour + ':' + minute
    };

    handlePageClick = (event) => {
        console.log(event);
        this.getList(event.selected);
        // const newOffset = (event.selected * itemsPerPage) % items.length;
        // console.log(
        //   `User requested page number ${event.selected}, which is offset ${newOffset}`
        // );
        // setItemOffset(newOffset);
    };

    render() {
        return (
            <>
                <header className="bg-white shadow">
                    <div className="py-6 px-4 sm:px-6 lg:px-8">
                        <h1 className="text-3xl font-bold text-gray-900">
                            Logs
                        </h1>
                    </div>
                </header>
                <main>
                    <div className="card p-3">
                        <div className="flex flex-col">
                            <div className="">
                                <div className="pb-0 align-middle inline-block min-w-full sm:px-2 lg:px-4">
                                {this.state.isLoading ? '...' : 
                                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Date
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        User
                                                    </th>
                                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                        Action
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {this.state.logsList.map((item, index) => {
                                                return (
                                                    <tr 
                                                        key={'i-' + item.id}
                                                        className={index %2 === 0 ? 'bg-white' : 'bg-gray-50'}
                                                        >
                                                        <td 
                                                            className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                        >
                                                            {this.formatDate(item.timestamp, 'dd/MM/Y H:i')}
                                                        </td>
                                                        <td 
                                                            className="truncate max-w-xs px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                                                        >
                                                            {item.tag ? item.tag : 'User'}
                                                        </td>
                                                        <td 
                                                            className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                                                            title={item.description}
                                                        >
                                                            <Truncate 
                                                                string={item.description}
                                                                length="100"
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })}
                                            </tbody>
                                        </table>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={this.handlePageClick}
                                            pageRangeDisplayed={5}
                                            pageCount={Math.ceil(this.state.nrResults / this.itemsPerPage)}
                                            previousLabel="<"
                                            renderOnZeroPageCount={null}
                                            containerClassName={'isolate inline-flex m-3 -space-x-px rounded-md shadow-sm'}
                                            // pageClassName={'relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                                            pageLinkClassName={'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                                            activeLinkClassName={'relative z-10 inline-flex items-center bg-indigo-600 px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'}
                                            breakLinkClassName={'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0'}
                                            previousLinkClassName={'relative inline-flex items-center rounded-l-md px-4 py-2 text-sm text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                                            nextLinkClassName={'relative inline-flex items-center rounded-r-md px-4 py-2 text-sm text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0'}
                                        />
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <footer 
                    className="fixed bottom-0 data-iframe-height h-4"
                    ref={ (footElement) => { this.footElement = footElement } }
                >&nbsp;</footer>
            </>
        );
    }
}

export default Logs;

function getHeaders() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = tokenString ? JSON.parse(tokenString).token : null;

  return {
      headers: {
        'Authorization': 'token ' + userToken
      }
  }
}
