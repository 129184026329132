import React, { useState, useCallback } from "react";
import CheckBox from "./CheckBox";

const CheckBoxGroup = React.forwardRef(
  (
    {
      options,
      id,
      selectedValues,
      name,
      onChange,
      onClick,
      errorText,
      checked,
      disabled,
      // remove,
      handleRemove
    },
    ref
  ) => {
    const error = errorText ? (
      <p className="mt-2 text-sm text-red-600">{errorText}</p>
    ) : null;
    //Mimicing the force update in the fucntional component
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);

    const handleOnchange = (val, checked) => {
      // console.log(val, checked);
      if (onChange) {
        if (checked) {
            let index = selectedValues.indexOf(val);
          if (index === -1) {
            selectedValues.push(val);
          }
        } else {
          let index = selectedValues.indexOf(val);
          if (index !== -1) {
            selectedValues.splice(index, 1);
          }
        }
        onChange([...selectedValues]);
      }
      // force update in functional component
      forceUpdate();
    };
    return (
      <div className="flex flex-col">
        <div className="flex flex-col pl-1">
          {options.map((option, index) => {
            // console.log(option);
            return (
              <div className="flex items-center justify-between" key={option.value}>
                <CheckBox
                  id={option.label}
                  labelText={option.label}
                  labelIcon={option.icon}
                  value={option.value}
                  name={option.value}
                  onChange={(e) => {
                    handleOnchange(option.value, e.target.checked);
                  }}
                  checked={
                    checked ? checked : selectedValues.includes(option.value)
                  }
                  {...option}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
        {error}
      </div>
    );
  }
);

CheckBoxGroup.defaultProps = {
  indeterminate: false,
  onChange: () => {},
  errorText: "",
  onClick: () => {},
  selectedValues: [],
  options: [],
  // remove: false,
  handleRemove: () => {}
};

export default CheckBoxGroup;

//SET OF OPTIONS ARE PASSED
//SET OF SELECTED OPTIONS ARE PASSED=
//WHENEVER CHANGED GIVE THE USER SELECTED ONES
