import React, { Component } from "react";
import Select from 'react-select'
import axios from "axios";
import CharacterCounter from 'react-character-counter'
import DatePicker from "react-datepicker";
import '../datepicker.css'
import * as constClass from './Constants';
import CheckBoxGroup from "./CheckBoxGroup";

function getPlatformIds(platforms) {
  var ids = [];
  if (platforms) {
    platforms.forEach(function (platform, index) {
      ids.push(platform.platform_type);
    });
  }
  return ids;
}

export default class KeywordModal extends Component {
  constructor(props) {
    super(props);    this.state = {
      isLoading: true,
      formItem: this.props.formItem,
      assetId: this.props.assetId,
      allLanguages: [],
      showLanguages: false,
      platformLanguages: []    
    };
  }

  componentDidMount() {
    this.getLanguages();

    var platformIds = [],
      selectedLangOptions = [];
    for (const platform of this.state.formItem.platforms) {
      if (!platformIds.includes(platform.platform_type)) {
        platformIds.push(platform.platform_type);
      }
      if (platform.platform_type === 1 && !selectedLangOptions.includes(platform.language)) {
        selectedLangOptions.push(platform.language);
      }
    }

    const showLanguages = platformIds.includes(1);
    this.setState({ showLanguages });

    let formItem = this.state.formItem;
    formItem.languages = selectedLangOptions;
    this.setState({ formItem });

    if (formItem.id) {
      let platformLanguages = [];
      if (platformIds.includes(1)) {
        platformLanguages.push({
          id: 1,
          icon: "fab fa-twitter fa-fw", 
          label: 'Twitter (' + selectedLangOptions.join(',') + ')'
        });
      }
      if (platformIds.includes(3)) {
        platformLanguages.push({
          id: 3,
          icon: "fab fa-youtube fa-fw", 
          label: "Youtube (all)"});
      }
      if (platformIds.includes(4)) {
        platformLanguages.push({
          id: 4,
          icon: "fab fa-reddit fa-fw", 
          label: "Reddit (all)"
        });
      }
      this.setState({ platformLanguages });
    }
  }

  handleChange = (e) => {
    let { name, value } = e.target;

    const formItem = { ...this.state.formItem, [name]: value };
    this.setState({ formItem });
  };

  setStartDate = (date) => {
      const formItem = { ...this.state.formItem, start_date: date };
      this.setState({ formItem });
  };

  setEndDate = (date) => {
    const formItem = { ...this.state.formItem, end_date: date };
    this.setState({ formItem });
  };

  setPlatforms = (selectedPlatforms) => {
    let platforms = [],
      platformIds = [];
    for (const platformId of selectedPlatforms) {
      platformIds.push(platformId);
      platforms.push({platform_type: platformId});
    }

    const showLanguages = platformIds.includes(1);
    this.setState({ showLanguages });

    const formItem = { ...this.state.formItem, platforms: platforms };
    this.setState({ formItem });
  };

  getLanguages = () => {
    axios
        .get(`${constClass.BACKEND_URI}/backend/api/languagesupport/`, getHeaders())
        .then((res) => {
            const temp = res.data;
            let allLanguages = [];
            temp.forEach(function (lang, index) {
                if (lang.twitter) {
                  allLanguages.push({value: lang.language_code, label: lang.language});
                }
            });
            this.setState({ allLanguages: allLanguages });
            this.setState({ isLoading: false });
        })
        .catch((err) => console.log(err));
  };

  setLanguages = (selectedLanguage) => {
    let selectedLangOptions = [];
    selectedLanguage.forEach(function (language) {
      selectedLangOptions.push(language);
    });

    const formItem = { ...this.state.formItem, languages: selectedLangOptions };
    this.setState({ formItem });
  };

  setLangOpen = () => {
    const langIsOpen = !this.state.langIsOpen;
    this.setState({ langIsOpen });
  };

  render() {
    const { toggle, onSave } = this.props;

    return (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-1/2 min-w-max my-6 mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">
                    {this.state.formItem.id ? 'Edit' : 'Create a new'} keyword
                  </h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => toggle(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                    <form 
                        className="space-y-6"
                        onSubmit={() => onSave(this.state.formItem)} 
                    >
                      <div>
                          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                              Keyword to scrape
                          </label>
                          <div className="mt-1">
                                <CharacterCounter
                                  value={this.state.formItem.name}
                                  maxLength={50}
                                >
                                  <input 
                                      id="name" 
                                      name="name" 
                                      type="text" 
                                      readOnly={this.state.formItem.id}
                                      value={this.state.formItem.name}
                                      onChange={this.handleChange}
                                      maxLength={50}
                                      placeholder="Enter Keyword name"
                                      required 
                                      className={(this.state.formItem.id ? 'cursor-not-allowed opacity-75 ' : '') + 'appearance-none max-w-lg block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'} 
                                  />
                                </CharacterCounter>
                                {this.state.formItem.id ? 
                                  <>
                                    <p className="mt-2 text-xs text-blue-300"><i className="fas fa-exclamation-circle"></i> The label of an existing keyword cannot be changed</p>
                                  </> : 
                                  null
                                }
                          </div>
                      </div>

                      <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label htmlFor="start_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                Start scraping
                            </label>
                            <div className="mt-1">
                                <DatePicker
                                    selected={ this.state.formItem.start_date }
                                    onChange={ this.setStartDate }
                                    name="start_date"
                                    minDate={ new Date('2020-01-01') }
                                    maxDate={ this.state.formItem.end_date ? new Date(this.state.formItem.end_date) : null }
                                    dateFormat="dd/MM/yyyy"
                                    placeholder="dd/mm/yyyy"
                                    autoComplete="off"
                                    required
                                />
                                <p className="mt-2 text-xs text-blue-300"><i className="fas fa-exclamation-circle"></i> Contact us for a start date earlier then jan 2020</p>
                            </div>
                        </div>

                        <div>
                            <label htmlFor="end_date" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                End scraping
                            </label>
                            <div className="mt-1">
                                <DatePicker
                                    selected={ this.state.formItem.end_date }
                                    onChange={ this.setEndDate }
                                    name="end_date"
                                    minDate={ this.state.formItem.start_date ? new Date(this.state.formItem.start_date) : new Date('2020-01-01') }
                                    dateFormat="dd/MM/yyyy"
                                    placeholder="dd/mm/yyyy"
                                    autoComplete="off"
                                />
                            </div>
                            <p className="mt-2 text-xs text-blue-300"><i className="fas fa-exclamation-circle"></i> For continues scraping don't add an end date</p>
                        </div>
                      </div>

                      {this.state.formItem.id ?
                      <>
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Platforms to scrape
                        </label>
                        <ul>
                        {this.state.platformLanguages.map((platform, index) => {
                          return (
                            <li key={platform.id}>
                              <i className={platform.icon}></i> 
                              {platform.label}
                            </li>
                          )
                        })}
                        </ul>
                      </> : 
                      <>
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Platforms to scrape
                          </label>
                          <div className="flex items-center justify-left">
                            <CheckBoxGroup
                              selectedValues={getPlatformIds(this.state.formItem.platforms)}
                              remove={"true"}
                              options={[
                                { value: 1, label: "Twitter", icon: "fab fa-twitter" },
                                // { value: 2, label: "Instagram", icon: "fab fa-instagram" },
                                { value: 3, label: "YouTube", icon: "fab fa-youtube" },
                                { value: 4, label: "Reddit", icon: "fab fa-reddit" }
                              ]}
                              disabled={ this.state.formItem.allow_past === false && this.state.formItem.start_date < new Date() }
                              onChange={ this.setPlatforms }
                            />
                          </div>
                        </div>

                        {this.state.showLanguages ? 
                          <div>
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Languages{this.state.formItem.platforms.length > 1 ? <> (only Twitter)</> : null}
                            </label>
                            <div>
                              <Select 
                                options={this.state.allLanguages}
                                name="language"
                                inputId="language"
                                isMulti
                                // isClearable={true}
                                value={this.state.formItem.languages} 
                                onChange={this.setLanguages}
                              />
                            </div>
                          </div> :
                          null
                        }
                      </div>
                      {this.state.formItem.allow_past === false && this.state.formItem.start_date < new Date() ? 
                        <p className="mt-2 text-xs text-blue-300"><i className="fas fa-exclamation-circle"></i> If the scraping period has a past date you cannot change the platforms</p> : 
                        null
                      }
                      </>
                      }

                      <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                        <button
                          type="button"
                          className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                          onClick={() => toggle(false)}
                        >
                          Close
                        </button>
                        <button 
                          disabled={!this.state.formItem.name || !this.state.formItem.start_date || this.state.formItem.platforms.length === 0} 
                          type="submit"
                          className={(!this.state.formItem.name || !this.state.formItem.start_date || this.state.formItem.platforms.length === 0 ? 'cursor-not-allowed opacity-75 ' : '') + 'inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'}
                        >
                          Save
                        </button>
                      </div>
                    </form>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
  );
}}
function getHeaders() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = tokenString ? JSON.parse(tokenString).token : null;

  return {
      headers: {
        'Authorization': 'token ' + userToken
      }
  }
}
